import { get, sortBy, maxBy } from 'lodash';
import { CoreConfigurationLabels } from 'client/data/models/inventory-core-configurations';
import { filterDiscontinuedStyles } from 'site-modules/shared/utils/vehicle-utils';

export function calculateEstimatedSavingsConfiguration(coreConfigurations) {
  return (
    coreConfigurations &&
    coreConfigurations.reduce(
      (toReturn, configuration) =>
        configuration.estimatedSavings > toReturn.estimatedSavings ? configuration : toReturn,
      { estimatedSavings: 0 }
    )
  );
}

export function buildCoreConfigurationVehicles(coreConfigurations) {
  if (!(coreConfigurations && coreConfigurations.length > 1)) {
    return coreConfigurations;
  }

  // if there are more than three configurations, we want have special logic of the first three to display
  let basic = null;
  let wellEquipped = null;
  let loaded = null;

  const lastVehicles = coreConfigurations.reduce((reducedConfigurations, configuration) => {
    if (CoreConfigurationLabels.BASIC === configuration.label && !basic) {
      basic = configuration;
    } else if (CoreConfigurationLabels.WELL_EQUIPPED === configuration.label && !wellEquipped) {
      wellEquipped = configuration;
    } else if (CoreConfigurationLabels.LOADED === configuration.label && !loaded) {
      loaded = configuration;
    } else {
      reducedConfigurations.push(configuration);
    }

    return reducedConfigurations;
  }, []);

  const firstVehicles = [];
  if (basic) {
    firstVehicles.push(basic);
  }
  if (wellEquipped) {
    firstVehicles.push(wellEquipped);
  }
  if (loaded) {
    firstVehicles.push(loaded);
  }
  return firstVehicles.concat(lastVehicles);
}

/**
 * Convert trimSummaries to TrimSummaries format
 * @param trimSummaries
 * @returns {*}
 */
export function buildTrimSummariesCoreConfigurationVehicles(trimSummaries) {
  if (!get(trimSummaries, 'length')) {
    return trimSummaries;
  }

  const mostPopularStyleId = get(maxBy(trimSummaries, 'shareBySalesSum'), 'styleId');

  return trimSummaries.map(trimSummary => ({
    suggestedPrice: get(trimSummary, 'pricing.suggestedPrice'),
    id: get(trimSummary, 'styleId'),
    style: get(trimSummary, 'photo.title'),
    trim: get(trimSummary, 'trimName'),
    inventoryCount: get(trimSummary, 'inventoryCount'),
    hasLeadFormDealers: get(trimSummary, 'hasLeadFormDealers'),
    baseMsrp: get(trimSummary, 'pricing.baseMsrp'),
    totalMsrp: get(trimSummary, 'pricing.totalMsrp'),
    tmv: get(trimSummary, 'pricing.tmv'),
    totalTmv: get(trimSummary, 'pricing.totalTmv'),
    photoProvider: get(trimSummary, 'photo.provider'),
    photoHref: get(trimSummary, 'photo.sources[0].link.href'),
    estimatedSavings: get(trimSummary, 'pricing.estimatedSavings'),
    shareBySalesSum: get(trimSummary, 'shareBySalesSum'),
    isMostPopular: mostPopularStyleId === get(trimSummary, 'styleId'),
  }));
}

/**
 * Convert styles to coreConfiguration format
 * @param styles
 * @returns {*}
 */
export function buildCoreConfigurationFallbackVehicles(styles) {
  return (
    styles &&
    styles.map &&
    sortBy(
      filterDiscontinuedStyles(
        styles.map(style => ({
          baseMsrp: get(style, 'price.baseMSRP'),
          id: style.id,
          trim: get(style, 'trim'),
          name: get(style, 'name'),
          modelName: get(style, 'modelName'),
          submodelName: get(style, 'submodels.name'),
          modelyearId: get(style, `model.defaultSubmodel.years[${get(style, 'year')}].modelYearId`),
          totalMsrp: get(style, 'price.totalMSRP'),
          bestDealPrice: get(style, 'price.bestDealPrice', 0),
          estimatedSavings: get(style, 'price.estimatedSavings'),
          lowestPrice: get(style, 'price.lowestPrice', 0),
          styleEndDate: get(style, 'styleEndDate'),
        }))
      ),
      'baseMsrp'
    )
  );
}

function getMinMedianMax(trim) {
  if (!trim || !trim.length) {
    return [];
  }

  return trim.length >= 3
    ? [
        { ...trim[0], label: CoreConfigurationLabels.BASIC },
        { ...trim[Math.floor(trim.length / 2)], label: CoreConfigurationLabels.WELL_EQUIPPED },
        { ...trim[trim.length - 1], label: CoreConfigurationLabels.LOADED },
      ]
    : trim;
}

export function getConfigurations({ coreConfigurations, coreConfigurationsFallback }) {
  return coreConfigurations && coreConfigurations.length > 0
    ? coreConfigurations.slice(0, 3)
    : getMinMedianMax(coreConfigurationsFallback); // only return three max
}

export function getSortedConfigurations({ coreConfigurations, coreConfigurationsFallback }) {
  const preferredConfigurations = get(coreConfigurations, 'length') ? coreConfigurations : coreConfigurationsFallback;
  const sortedConfigurations =
    preferredConfigurations &&
    preferredConfigurations.sort(
      (configA, configB) => (configA.totalMsrp || configA.baseMsrp) - (configB.totalMsrp || configB.baseMsrp)
    );
  return sortedConfigurations && sortedConfigurations.length ? sortedConfigurations.slice(0, 6) : [];
}

export function sortConfigurationsByPrice(configurations) {
  // concat used to not mutate initial array
  return configurations
    .concat()
    .sort(
      (configA, configB) =>
        (configA.bestDealPrice || configA.totalMsrp || configA.baseMsrp) -
        (configB.bestDealPrice || configB.totalMsrp || configB.baseMsrp)
    );
}

export function getHighestLowestESP(configurations) {
  // concat used to not mutate initial array
  const sortedConfs =
    configurations && configurations.concat().sort((configA, configB) => configA.bestDealPrice - configB.bestDealPrice);

  return {
    lowestESP: get(sortedConfs[0], 'bestDealPrice', 0),
    highestESP: get(sortedConfs[sortedConfs.length - 1], 'bestDealPrice', 0),
  };
}

export function getConfigurationByStyleId({ coreConfigurations, coreConfigurationsFallback, styleId }) {
  if (!styleId) {
    return getConfigurations({ coreConfigurations, coreConfigurationsFallback })[0];
  }

  return (
    (!!get(coreConfigurations, 'length') && coreConfigurations.find(({ id }) => id === styleId)) ||
    (!!get(coreConfigurationsFallback, 'length') && coreConfigurationsFallback.find(({ id }) => id === styleId))
  );
}

export function getChartConfiguration(coreConfigurations) {
  let result = null;

  if (coreConfigurations && coreConfigurations.length > 0) {
    result = coreConfigurations.find(
      configuration => configuration && configuration.label === CoreConfigurationLabels.BASIC
    );
  }

  return result;
}
